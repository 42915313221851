import { Component, ViewChild } from '@angular/core';
import { ParceiroTipoModel } from 'src/assets/model/ParceiroTipoModel';
//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  
  public title:any = '';
  public parceiroTipo: ParceiroTipoModel = new ParceiroTipoModel();
  
  // {
  //     guid : '',//'ec276ff0-d77e-4247-a00e-2f0aa333f8fb', 
  //     exibeObservacao: false, 
  //     constante: ''}

}
