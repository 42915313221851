import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Filtro } from 'src/assets/model/FiltroModel';
import { ConsultaProvider } from 'src/providers/consulta/consulta';
import { AppComponent } from '../app.component';


@Component({
    selector: 'app-consulta-resultado',
    templateUrl: './consulta-resultado.component.html',
    styleUrls: ['./consulta-resultado.component.css']
})
export class ConsultaResultadoComponent implements OnInit {
    
    public fim: boolean = false;
    public alwaysCallb: boolean = true; //instrui o scroller a sempre acionar eventos
    public throttle: number = 300;
    public scrollDistance: number = 2;
    public scrollUpDistance: number = 2;

    public parceiroSelecionado: any = {};
    public resultadoConsulta: any = {nivel : 1, parceiros : []};
    //public parceiros: Array<any> = [];
    public filtroTemp: any = {};
    public loading: boolean = false;
    public ultimaDescricao: string = '';
    public filtro: Filtro = new Filtro();
    public constanteTipo: string = this.activatedRoute.snapshot.params.constante;
    public parceiroTipo: any = {};

    @ViewChild('btnShowLocalizacao') btnShowLocalizacao: any;
    @ViewChild('frameMaps') frameMaps: any;

    constructor(
        private router: Router,
        private consultaProv: ConsultaProvider,
        public appComponent: AppComponent,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit(): void {
    };

    ngAfterViewInit() {

        if (localStorage.getItem(this.constanteTipo + '_ParceiroTipo') !== null) {
            this.parceiroTipo = JSON.parse(localStorage.getItem(this.constanteTipo + '_ParceiroTipo')!);
        }
        else {
            this.router.navigate(['/consulta-erro']);
        }

        if (localStorage.getItem(this.constanteTipo + '_Filtro') !== null) {
            this.filtroTemp = JSON.parse(localStorage.getItem(this.constanteTipo + '_Filtro')!);
            this.filtro.descricao = this.filtroTemp.descricao;
            this.filtro.descricaoEspecialidade = this.filtroTemp.descricaoEspecialidade;
            this.filtro.uf = this.filtroTemp.uf;
            this.filtro.cidade = this.filtroTemp.cidade;
            this.filtro.bairro = this.filtroTemp.bairro;
        }

        this.consultar();

    };

    showMaps(parceiro: any): void {

        this.parceiroSelecionado = parceiro;

        this.frameMaps.nativeElement.src = 'https://maps.google.com/maps?saddr=' +
            parceiro.logradouro +
            ', ' + parceiro.numero +
            ' ' + parceiro.bairro +
            ', ' + parceiro.cidade +
            ', ' + parceiro.uf +
            ', ' + parceiro.cep +
            ', Brasil' +
            '&output=embed&z=14&Iwloc=addr';

    };

    closeMaps(): void {

        this.frameMaps.nativeElement.src = '/page-load';

    };

    goBack(): void {

        this.router.navigate(['/consulta/' + this.constanteTipo]);

    };

    verificarShowLocalizacao(): void {

        if (this.filtro.uf === null || this.filtro.uf === '' || this.filtro.uf === undefined) {
            this.btnShowLocalizacao.nativeElement.click();
        }

    };

    atualizarLocalizacao(): void {

        setTimeout(() => {
            this.verificarShowLocalizacao();
        }, 1000);

        localStorage.setItem(this.constanteTipo + '_Filtro', JSON.stringify(this.filtro));

    };

    consultar(): void {

        this.fim = false;
        this.filtro.offSet = 0;
        this.filtro.limit = 10;
        this.filtro.guidParceiroTipo = this.parceiroTipo.guid;
        localStorage.setItem(this.constanteTipo + '_Filtro', JSON.stringify(this.filtro));
        this.ultimaDescricao = this.filtro.descricao;
        this.loading = true;
        this.resultadoConsulta.parceiros = [];
        this.filtro.nivel = 0;
        this._consultar();
    };

    _consultar(): void {

        this.consultaProv.parceiros(this.filtro).then((result: any) => {

            console.log(result);
            this.filtro.nivel  = result.nivel;
            result.parceiros.forEach((cto: any) => {

                let temp = this.resultadoConsulta.parceiros.find((x:any) => x.guid === cto.guid);
                if (temp === null || temp === undefined) {

                    this.resultadoConsulta.parceiros.push(cto);
                }
            });

            if(result.parceiros.length === 0)
            {                
                this.fim = true;
            }

            this.loading = false;

        }, (error: any) => {

            console.log(error);

            this.router.navigate(['/consulta-erro']);
        });
    };

    colocarBold(str: string): string {

        if (str === null || str === '' || this.ultimaDescricao === null || this.ultimaDescricao === '') {
            return str;
        }

        var strArray = this.ultimaDescricao.split(" ");

        let ret: string = str;

        for (var i = 0; i < strArray.length; i++) {
            var strArray2 = strArray[i].split("+");
            for (var ii = 0; ii < strArray2.length; ii++) {
                ret = ret.replace(strArray2[ii].toUpperCase(), strArray2[ii].toUpperCase().bold());
            }
        }

        return ret;
    };

    onScrollDown() {

        if(this.fim)
        {                
            console.log('fim');
            return;
        }

        this.loading = true;
        this.filtro.offSet += this.filtro.limit + 1;
        this._consultar();

    };

    onKey(event: any) {

        if (event.ctrlKey === false && event.keyCode === 13) {
            this.consultar();
        }

    };
}
