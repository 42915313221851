<div class="container">
    <div class="row justify-content-md-center">
        <div class="col-sm-12 col-md-10 col-xl-6 col-lg-6">
            <img src="../../assets/icon/question-circle-fill.svg" alt="Ajuda" width="30" height="30" class="icone-ajuda"
                data-toggle="modal" data-target="#modalAjuda">
            <h1 class="display-4 text-center cursor-hand" (click)="goBack()"><img
                    src="../assets/img/logo-consulta-resultado.png" /></h1>

            <div class="row justify-content-md-center div-linha cursor-hand" #btnShowLocalizacao data-toggle="modal"
                data-target="#exampleModal">
                <div class="col-12 text-center">
                    <img src="../assets/icon/geo-alt-fill.svg" class="icone" alt="Bootstrap" width="25" height="25">
                    <span *ngIf="filtro.bairro !== null && filtro.bairro !== ''">{{filtro.bairro}}</span>
                    <span *ngIf="filtro.cidade !== null && filtro.cidade !== ''"><span
                            *ngIf="filtro.bairro !== null && filtro.bairro !== ''">,</span>&nbsp;{{filtro.cidade}}</span>
                    <span *ngIf="filtro.uf !== null && filtro.uf !== ''"><span
                            *ngIf="filtro.cidade !== null && filtro.cidade !== ''">,</span>&nbsp;{{filtro.uf}}</span>
                </div>
            </div>

            <div class="row justify-content-md-center div-linha">
                <div class="col-12" style="position: relative;">
                    <input type="text" class="form-control" [(ngModel)]="filtro.descricao"
                        placeholder="Pesquise um parceiro" (keyup)="onKey($event)" autocomplete="off">
                    <img src="../assets/icon/search.svg" alt="Buscar" width="20" (click)="consultar()" height="20"
                        class="cursor-hand icone" style="position: absolute; right: 25px; top: 12px;">
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="search-results" style="height: 90%" infinite-scroll [infiniteScrollDistance]="scrollDistance"
        [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle" [alwaysCallback]="alwaysCallb"
        (scrolled)="onScrollDown()">
        <div class="row justify-content-md-center" *ngFor="let parceiro of resultadoConsulta.parceiros"
            data-toggle="modal" data-target="#modalMaps" (click)="showMaps(parceiro)">
            <div class="col-12 col-sm-12 col-md-8 col-xl-8 col-lg-7">
                <div class="card mt-0 border-0 pt-1 active pb-0 px-3">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-11 col-sm-11 col-md-10 col-xl-10 col-lg-10">
                                <h6 class="card-title">
                                    <b><label [innerHTML]="colocarBold(parceiro.nome)"></label></b>
                                </h6>
                            </div>
                            <div class="col-1 col-sm-1 col-md-2 col-xl-2 col-lg-2 text-right">
                                
                                <label class="label-tipo-parceiro"
                                    *ngIf="parceiroTipo.guid === '5a4b41d3-b055-4ab2-b4fd-e9ba1a069f8f'">{{parceiro.descricaoParceiroTipo}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-xl-7 col-lg-6 mt-2">
                                <div class="col-12">
                                    <h6 class="card-subtitle mb-2 text-muted">
                                        <div class="row cursor-hand">
                                            <div class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1 cursor-hand">
                                                <img src="../assets/icon/geo-fill.svg" alt="" width="18" height="18"
                                                    class="icone">
                                            </div>
                                            <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11 cursor-hand">
                                                <div>
                                                    <label [innerHTML]="colocarBold(parceiro.logradouro)"
                                                        class="cursor-hand"></label>
                                                    <label *ngIf="parceiro.numero !== null && parceiro.numero !== ''"
                                                        class=" cursor-hand">, {{parceiro.numero}}</label>
                                                </div>
                                                <div>
                                                    <label *ngIf="parceiro.bairro !== null && parceiro.bairro !== ''"
                                                        class=" cursor-hand">{{parceiro.bairro}}</label>
                                                    <label *ngIf="parceiro.cidade !== null && parceiro.cidade !== ''"
                                                        class=" cursor-hand">
                                                        <label
                                                            *ngIf="parceiro.bairro !== null && parceiro.bairro !== ''">,</label>
                                                        {{parceiro.cidade}}
                                                    </label>

                                                    <label *ngIf="parceiro.uf !== null && parceiro.uf !== ''"
                                                        class=" cursor-hand">
                                                        <label
                                                            *ngIf="parceiro.cidade !== null && parceiro.cidade !== ''">,</label>
                                                        {{parceiro.uf}}
                                                    </label>
                                                </div>
                                                <div style="font-size: 10px;"
                                                    *ngIf="parceiro.complemento !== null && parceiro.complemento !== ''">
                                                    <label class=" cursor-hand">({{parceiro.complemento}})</label>
                                                </div>
                                            </div>
                                        </div>
                                    </h6>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-xl-5 col-lg-6 mt-2">
                                <div class="col-12">
                                    <h6 class="card-subtitle mb-2 text-muted">
                                        <div class="row">
                                            <div class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1"
                                                style="color: rgb(0, 35, 148);">
                                                <img src="../assets/icon/telephone.svg" alt="" width="15" height="15"
                                                    class="icone">
                                            </div>
                                            <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11">
                                                <div *ngIf="parceiro.telefone !== null && parceiro.telefone !== ''">
                                                    <label [innerHTML]="colocarBold(parceiro.telefone)"></label>
                                                </div>
                                                <div *ngIf="parceiro.fax !== null && parceiro.fax !== ''">
                                                    <label [innerHTML]="colocarBold(parceiro.fax)"> Fax</label>
                                                </div>
                                                <div *ngIf="parceiro.celular !== null && parceiro.celular !== ''">
                                                    <label [innerHTML]="colocarBold(parceiro.celular)"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </h6>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 mt-2"
                                *ngIf="parceiro.especialidades.length > 0">
                                <label class="hashtag-especialidade"
                                    *ngFor="let especialidade of parceiro.especialidades"
                                    [innerHTML]="colocarBold(especialidade)"></label>
                            </div>
                        </div>
                        <div class="row" *ngIf="parceiro.exibeObservacao">
                            <span
                                style="font-size: 10px; color: rgb(0, 35, 148);"><br /><b>{{parceiro.consultaLabelObservacao}}</b></span>
                            <div class="col-md-auto ">
                                <span class="card-subtitle mb-2 text-muted">{{colocarBold(parceiro.observacao)}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-white px-3">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-md-center div-linha" *ngIf="loading">
        <div class="col-12  text-center ">
            <img src="../../assets/img/loading.gif" style="max-width: 70px;" />
        </div>
    </div>
    <div class="row justify-content-md-center div-linha" *ngIf="!loading && resultadoConsulta.parceiros.length === 0">
        <div class="col-12  text-center div-registro-nao-localizado">
            <label>Não foi localizado registro que atenda os dados da consulta!</label>
            <br />
            <img src="../../assets/img/registro-nao-localizado.png" style="max-width: 90px;" />
        </div>
    </div>
    <div class="row justify-content-md-center div-linha" *ngIf="fim && resultadoConsulta.parceiros.length > 0">
        <div class="col-12  text-center div-registro-nao-localizado">
            <label>Fim</label>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" #myModel tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Informe a localização</h5>
            </div>
            <div class="modal-body">
                <app-consulta-localizacao [parceiroTipo]="parceiroTipo" [filtro]="filtro"></app-consulta-localizacao>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal"
                    (click)="atualizarLocalizacao()">OK</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalMaps" #modalMaps tabindex="-1" role="dialog" aria-labelledby="modalMaps"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header text-right">
                {{parceiroSelecionado.nome}}
                <button type="button" class="btn btn-primary close" data-dismiss="modal" aria-label="Close"
                    (click)="closeMaps()">X</button>
            </div>
            <div class="modal-body">
                <iframe #frameMaps style="width: 100%;" scrolling='no' height='500' frameborder='0' id='map'
                    marginheight='0' marginwidth='0' src='/page-load'></iframe>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalAjuda" #myModel tabindex="-1" role="dialog" aria-labelledby="modalAjuda"
    aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Ajuda</h5>
                <button type="button" class="btn btn-primary close" data-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <app-page-ajuda></app-page-ajuda>
            </div>
        </div>
    </div>
</div>