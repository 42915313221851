import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consulta-home',
  templateUrl: './consulta-home.component.html',
  styleUrls: ['./consulta-home.component.css']
})
export class ConsultaHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
