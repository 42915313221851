import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsultaErroComponent } from './consulta-erro/consulta-erro.component';
import { ConsultaHomeComponent } from './consulta-home/consulta-home.component';
import { ConsultaResultadoComponent } from './consulta-resultado/consulta-resultado.component';
import { ConsultaComponent } from './consulta/consulta.component';
import { PageLoadComponent } from './page-load/page-load.component';

const routes: Routes = [
  {
    path: '',
    component: ConsultaHomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'consulta',
    component: ConsultaHomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'page-load',
    component: PageLoadComponent,
    pathMatch: 'full',
  },
  {
    path: 'consulta-erro',
    component: ConsultaErroComponent,
    pathMatch: 'full',
  },
  {
    path: 'consulta/:constante',
    component: ConsultaComponent,
    pathMatch: 'full',
  },
  {
    path: 'consulta-resultado/:constante',
    component: ConsultaResultadoComponent,
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
