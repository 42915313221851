import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NumberValueAccessor } from '@angular/forms';
import { Router } from '@angular/router';
import { ConsultaProvider } from 'src/providers/consulta/consulta';

@Component({
  selector: 'app-consulta-localizacao',
  templateUrl: './consulta-localizacao.component.html',
  styleUrls: ['./consulta-localizacao.component.css']
})
export class ConsultaLocalizacaoComponent implements OnInit {

  @Input() filtro: any = {};
  @Input() parceiroTipo: any = {};
  @Output() clickItem = new EventEmitter();

  public ufs: Array<any> = [];
  public cidades: Array<any> = [];
  public bairros: Array<any> = [];

  constructor(
    private router: Router,
    private consultaProv: ConsultaProvider
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    
    if (localStorage.getItem("Ufs") !== null) {
      this.ufs = JSON.parse(localStorage.getItem("Ufs")!);
    }
    else {
      this.carregarUfs();
    }

    if (localStorage.getItem("Cidades") !== null) {
      this.cidades = JSON.parse(localStorage.getItem("Cidades")!);
    }

    if (localStorage.getItem("Bairros") !== null) {
      this.bairros = JSON.parse(localStorage.getItem("Bairros")!);
    }
  };

  carregarUfs(): void {

      this.consultaProv.ufs().then((result: any) => {

        localStorage.setItem("Ufs", JSON.stringify(result));
        console.log(result);
        this.ufs = result;
  
      }, (error: any) => {
  
        console.log(error);
        
        this.router.navigate(['/consulta-erro']);
  
      });
  }

  carregarCidades(): void {

    this.filtro.cidade = '';
    this.filtro.bairro = '';
    this.cidades = [];
    this.bairros = [];

    if (this.filtro.uf === '' || this.filtro.uf === null || this.filtro.uf === undefined) {
      return;
    }

    this.consultaProv.cidades(this.parceiroTipo.guid, this.filtro.uf).then((result: any) => {
      localStorage.setItem("Cidades", JSON.stringify(result));

      console.log(result);
      this.cidades = result;

    }, (error: any) => {

      console.log(error);

      this.router.navigate(['/consulta-erro']);
    });
  }

  carregarBairros(): void {

    this.filtro.bairro = '';
    this.bairros = [];


    if (this.filtro.cidade === '' || this.filtro.cidade === null || this.filtro.cidade === undefined) {
      return;
    }

    this.consultaProv.bairros(this.parceiroTipo.guid, this.filtro.cidade).then((result: any) => {

      localStorage.setItem("Bairros", JSON.stringify(result));

      console.log(result);
      this.bairros = result;

    }, (error: any) => {

      console.log(error);
      this.router.navigate(['/consulta-erro']);
    });

  }
}
