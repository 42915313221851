<div class="container">
  <div class="row justify-content-md-center">
    <div class="col-sm-12 col-md-10 col-xl-6 col-lg-6">
      <img src="../../assets/icon/question-circle-fill.svg" alt="Ajuda" width="30" height="30" class="icone-ajuda" data-toggle="modal" data-target="#modalAjuda">

      <h1 class="display-4 text-center"><img src="../assets/img/logo-consulta-main.png"></h1>

      <div class="row justify-content-md-center div-linha cursor-hand" data-toggle="modal" #btnShowLocalizacao data-target="#exampleModal">
        <div class="col-12 text-center">
          <img src="../assets/icon/geo-alt-fill.svg" alt="localizacao" width="25" height="25" class="icone">
          <span *ngIf="filtro.bairro !== null && filtro.bairro !== ''">{{filtro.bairro}}</span>
          <span *ngIf="filtro.cidade !== null && filtro.cidade !== ''"><span
              *ngIf="filtro.bairro !== null && filtro.bairro !== ''">,</span>&nbsp;{{filtro.cidade}}</span>
          <span *ngIf="filtro.uf !== null && filtro.uf !== ''"><span
              *ngIf="filtro.cidade !== null && filtro.cidade !== ''">,</span>&nbsp;{{filtro.uf}}</span>
        </div>
      </div>
      <div class="row justify-content-md-center div-linha">
        <div class="col-12" style="position: relative;">
          <input type="text" class="form-control" [(ngModel)]="filtro.descricao"
            placeholder="{{parceiroTipo.consultaLabelBusca}}" (keyup)="onKey($event)" autocomplete="true">
          <img src="../assets/icon/search.svg" alt="Buscar" width="20" (click)="consultar()" class="cursor-hand icone"
            height="20" style="position: absolute; right: 25px; top: 12px;">
        </div>
      </div>

      <div class="row justify-content-md-center div-linha">
        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-left">
          <br />
          <p class="mb-2 text-muted">
            &nbsp;&nbsp;&nbsp;<b>"Todas estas palavras (ex: casa+saude)"</b>: os resultados usarão todas as palavras inseridas.
          </p>
          <!-- <br />
            <p class="mb-2 text-muted">
              &nbsp;&nbsp;&nbsp;<b>"Qualquer uma destas palavras (ex: casa saude)"</b>: os resultados incluirão pelo menos uma das palavras inseridas.
            </p> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" #myModel tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Informe a localização</h5>
      </div>
      <div class="modal-body">
        <app-consulta-localizacao [parceiroTipo]="parceiroTipo" [filtro]="filtro"></app-consulta-localizacao>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="atualizarLocalizacao()">OK</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalAjuda" #myModel tabindex="-1" role="dialog" aria-labelledby="modalAjuda"
  aria-hidden="true" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Ajuda</h5>
        <button type="button" class="btn btn-primary close" data-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div class="modal-body">
        <app-page-ajuda></app-page-ajuda>
      </div>
    </div>
  </div>
</div>