<div class="row justify-content-md-center div-linha" *ngIf="filtro.uf === '' || filtro.uf === null">
  <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
    <h6 class="card-subtitle mb-2 text-muted my-fg-red">*Selecione ao menos o estado</h6>
  </div>
</div>
<div class="row justify-content-md-center div-linha">
  <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12">
    <select class="form-select" aria-label="Default select example" [(ngModel)]="filtro.uf"
      (change)="carregarCidades()">
      <option selected value="">--Selecione o Estado</option>
      <option *ngFor="let uf of ufs" value="{{uf?.sigla}}">{{uf?.descricao}}</option>
    </select>
  </div>
</div>
<div class="row justify-content-md-center div-linha">
  <div class="col-sm-12 col-md-12 col-xl-12 col-lg-12">
    <select class="form-select" aria-label="Default select example" [(ngModel)]="filtro.cidade"
      (change)="carregarBairros()">
      <option selected value="">--Selecione uma Cidade</option>
      <option *ngFor="let cidade of cidades" value="{{cidade?.descricao}}">{{cidade?.descricao}}</option>
    </select>
  </div>
</div>
<div class="row justify-content-md-center div-linha">
  <div class="col-sm-12 col-md-12 col-xl-12 col-lg-12">
    <select class="form-select" aria-label="Default select example" [(ngModel)]="filtro.bairro">
      <option selected value="">--Selecione um Bairro</option>
      <option *ngFor="let bairro of bairros" value="{{bairro?.descricao}}">{{bairro?.descricao}}</option>
    </select>
  </div>
</div>