import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Filtro } from 'src/assets/model/FiltroModel';
import { Util } from 'src/core/util';
import { ConsultaProvider } from 'src/providers/consulta/consulta';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.css']
})
export class ConsultaComponent implements OnInit {

  @ViewChild('myModel') myModal: any;
  @ViewChild('btnShowLocalizacao') btnShowLocalizacao: any;

  public filtroTemp: any = {};
  public parceiroTipo: any = {};
  public filtro: Filtro = new Filtro();
  public constanteTipo: string = this.activatedRoute.snapshot.params.constante;

  constructor(
    private router: Router,
    private consultaProv: ConsultaProvider,
    private appComponent: AppComponent,
    private activatedRoute: ActivatedRoute
  ) {

    console.log('Load Component');

  };

  ngOnInit(): void {
    
    this.consultaProv.parceiroTipo(this.constanteTipo).then((result: any) => {

      console.log(result);

      if (result === null) {
        this.router.navigate(['/consulta-erro']);
      }

      this.appComponent.parceiroTipo = result;
      this.parceiroTipo = result;
      localStorage.setItem(this.constanteTipo + '_ParceiroTipo', JSON.stringify(result));

    }, (error: any) => {

      console.log(error);
      this.router.navigate(['/consulta-erro']);

    });

    if (localStorage.getItem(this.constanteTipo + '_Filtro') !== null) {
      this.filtroTemp = JSON.parse(localStorage.getItem(this.constanteTipo + '_Filtro')!);
      this.filtro.descricao = '';//this.filtroTemp.descricao;
      this.filtro.descricaoEspecialidade = this.filtroTemp.descricaoEspecialidade;
      this.filtro.uf = this.filtroTemp.uf;
      this.filtro.cidade = this.filtroTemp.cidade;
      this.filtro.bairro = this.filtroTemp.bairro;
    }

  };

  ngAfterViewInit() {
    
    setTimeout(() => {
      this.verificarShowLocalizacao();
    }, 1000);

  };

  verificarShowLocalizacao(): void {

    if (this.filtro.uf === null || this.filtro.uf === '' || this.filtro.uf === undefined) {
      this.btnShowLocalizacao.nativeElement.click();
    }

  };

  atualizarLocalizacao(): void {

    setTimeout(() => {
      this.verificarShowLocalizacao();
    }, 1000);

    localStorage.setItem(this.constanteTipo + '_Filtro', JSON.stringify(this.filtro));

  };

  consultar(): void {

    localStorage.setItem(this.constanteTipo + '_Filtro', JSON.stringify(this.filtro));
    this.router.navigate(['/consulta-resultado/' + this.constanteTipo]);

  };

  onKey(event: any) {

    console.log(event);
    if (event.ctrlKey === false && event.keyCode === 13) {
      this.consultar();
    }

  };
}
