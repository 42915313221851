import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HttpModule } from '@angular/http';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConsultaResultadoComponent } from './consulta-resultado/consulta-resultado.component';
import { ConsultaComponent } from './consulta/consulta.component';
import { ApiProvider } from 'src/providers/api/api';
import { ConsultaProvider } from 'src/providers/consulta/consulta';
import { ConsultaLocalizacaoComponent } from './component/consulta-localizacao/consulta-localizacao.component';
import { ConsultaHomeComponent } from './consulta-home/consulta-home.component';
import { ConsultaErroComponent } from './consulta-erro/consulta-erro.component';
import { PageLoadComponent } from './page-load/page-load.component';
import { PageAjudaComponent } from './page-ajuda/page-ajuda.component';

@NgModule({
  declarations: [
    AppComponent,
    ConsultaResultadoComponent,
    ConsultaComponent,
    ConsultaLocalizacaoComponent,
    ConsultaHomeComponent,
    ConsultaErroComponent,
    PageLoadComponent,
    PageAjudaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    InfiniteScrollModule,
    RouterModule.forRoot([
      //{path: 'consulta', component: ConsultaComponent},
      //{path: 'consulta-resultado', component: ConsultaResultadoComponent},
    ]),
  //  NgbModule
  ],
  providers: [
    ApiProvider,
    ConsultaProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
