export class ParceiroTipoModel
{
    public guid: string = '';
    public constante: string = '';
    public descricao: string = '';
    public consultaTitulo: string = '';
    public consultaSubTitulo: string = '';
    public consultaLabelBusca: string = '';
    public consultaLabelEspecialidade: string = '';
    public consultaLabelObservacao: string = '';
    public consultaImg: string = '';
    public exibeObservacao: boolean = true;
    public ativo: boolean = true;
  }