import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../../core/appSettings';
//import { DefaultRequestOptions } from '../../core/defaultRequestOptions';
import { Observable } from 'rxjs';

@Injectable()
export class ApiProvider {

  constructor(
    public http: Http
    ) {
    console.log('Hello ApiProvider Provider');
  }

  get(Rota:any) {
    return new Promise((resolve, reject) => {
      let url = AppSettings.serverApi.url + AppSettings.serverApi.versionBase + Rota;
      
      //this.http.get(url, new DefaultRequestOptions())
      this.http.get(url)
        .subscribe((result: any) => {
          resolve(result._body === '' ? null : result.json());
        },
        (error) => {
          console.log('erro: '+  JSON.stringify(error));
          reject(this._ClassificarRetorno(error));
        });
    });
  }


  // get2(Rota:any): Observable<any> {
    
  //   let url = AppSettings.serverApi.url + AppSettings.serverApi.versionBase + Rota;

  //   let retorno = new Observable<any>(obs => {

  //     this.http.get(url, new DefaultRequestOptions())
  //     .map(res => res.json())
  //               .subscribe(rss => {
  //                 obs.next(rss);
  //               }, error =>{

  //                 //obs.error('Usuário ou senha inválidos!');

  //                 console.log('erro: '+  JSON.stringify(error));

  //               let erro: any = { code: '', action: 'MSG', message: '', page: '', root: false};

  //               switch(error.status)
  //               {
  //                 case 401:
  //                   erro = {code: '', action: 'OPEN_PAGE', message: 'Autorização negada', page: 'LoginPage', root: true};
  //                 break;

  //                 case 404:
  //                   erro = {code: '', action: 'MSG', message: 'Rota não encontrada.', page: ''};
  //                 break;

  //                 default:
  //                   erro = {code: '', action: 'MSG', message: 'Sem comunicação com o servidor', page: ''};
  //                 break;
  //               }

  //               obs.error(erro);

  //             } );
        
  //   });
  //   return retorno;
  // }

  post(Rota: string, Model: any) {
    return this.postRequestOption(Rota, Model, true);
  }

  postRequestOption(Rota:string, Model:any, PassRequestOption: boolean) {
    
    return new Promise((resolve, reject) => {
      let url = AppSettings.serverApi.url + AppSettings.serverApi.versionBase + Rota;
      
      //this.http.post(url, Model, new DefaultRequestOptions())
      this.http.post(url, Model)
        .subscribe((result: any) => {
          resolve(result._body === '' ? null : result.json());
        },
        (error) => {
          console.log('erro: '+  JSON.stringify(error));
          reject(this._ClassificarRetorno(error));
        });
    });
  }


  put(Rota:any, Model:any) {
    return new Promise((resolve, reject) => {
      let url = AppSettings.serverApi.url + AppSettings.serverApi.versionBase + Rota;
      
      //this.http.put(url, Model, new DefaultRequestOptions())
      this.http.put(url, Model)
        .subscribe((result: any) => {
          resolve(result._body === '' ? null : result.json());
        },
        (error) => {
          console.log('erro: '+  JSON.stringify(error));
          reject(this._ClassificarRetorno(error));
        });
    });
  }


  delete(Rota:any) {
    return new Promise((resolve, reject) => {
      let url = AppSettings.serverApi.url + AppSettings.serverApi.versionBase + Rota;
      
      //this.http.delete(url, new DefaultRequestOptions())
      this.http.delete(url)
        .subscribe((result: any) => {
          resolve(result._body === '' ? null : result.json());
        },
        (error) => {
          console.log('erro: '+  JSON.stringify(error));
          reject(this._ClassificarRetorno(error));
        });
    });
  }

  postNotToken(Rota:any, Model:any) {
    return new Promise((resolve, reject) => {
      let url = AppSettings.serverApi.url + AppSettings.serverApi.versionBase + Rota;
      
      this.http.post(url, Model)
        .subscribe((result: any) => {
          resolve(result._body === '' ? null : result.json());
        },
        (error) => {
          console.log('erro: '+  JSON.stringify(error));
          reject(error.json());
        });
    });
  }

  _ClassificarRetorno(error:any)
  {
    let code = Math.floor(error.status);
      let erro: any = { code: '', action: 'MSG', titulo: '', message: '', page: '', root: false};
      switch(code)
      {
        case 400:
          erro = {code: '400', action: 'MSG', titulo: 'Autorização negada', message: 'O usuário não tem autorização.', page: ''};
        break;

        case 401:
          erro = {code: '401', action: 'OPEN_PAGE', titulo: 'Autorização negada', message: 'O usuário não tem autorização para acessar esse recurso.', page: 'login', root: true};
        break;

        case 404:
          erro = {code: '404', action: 'MSG', titulo: 'Rota não encontrada', message: 'Não foi possível encontrar a rota para realizar a operação.', page: ''};
        break;
        
        case 412:
          erro = {code: '412', action: 'MSG', titulo: 'Restrição do formulário', message: 'Campos de preenchimento obrigatórios', page: ''};
        break;

        //Mensagem enviada pela api.
        case 417:
          erro = {code: '417', action: 'MSG', titulo: 'Mensagem de retorno', message: error._body, page: ''};
        break;
        
        case 502:
          erro = {code: '502', action: 'MSG', titulo: 'Sem comunicação', message: 'O servidor não respondeu a requisição.', page: ''};
        break;

        case 500:
          erro = {code: '500', action: 'MSG',titulo: 'Falha na comunicação',  message: 'Dificuldades na comuicação com o servidor.', page: ''};
        break;

        default:
          erro = {code: '', action: 'MSG', titulo: 'Falha na comunicação', message: 'Dificuldades na comuicação com o servidor.', page: ''};
        break;
      }

    return erro;
  }

}
