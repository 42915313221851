/**
 * Configurações globais do APP,
 * possui as constantes e demais configurações globais
 */
export const AppSettings = {
    nhaAuth: {
        Free: 0,
        LoginRequired: 1,
        NoAutorized: 2,
        AdminRequired: 3,
        Autorized: 4
    },
    serverApi: {        
        //LOCAL
        //url: "https://localhost:5001",
        url: "https://ambep-api.azurewebsites.net",
        versionBase: "/api/v1",
    }
}