import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consulta-erro',
  templateUrl: './consulta-erro.component.html',
  styleUrls: ['./consulta-erro.component.css']
})
export class ConsultaErroComponent implements OnInit {

  private interval:any = null;
  private timeout:any = null;
  public segundos: number = 5;
  constructor(
    private router: Router) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {this.segundos--}, 1000);
    this.timeout = setTimeout(() => {this.goHome()}, 5000);
  }

  ngOnDestroy() {
    console.log('DashboardComponent:OnDestroy');
    clearInterval(this.interval);
    clearInterval(this.timeout);
}

  goHome():void {

    this.router.navigate(['/consulta']);
  }

  percentualProgress():number {
    console.log(120 - (this.segundos * 20));
    return (120 - (this.segundos * 20)) * 3.5;
  }
}
