<div class="row justify-content-md-center div-linha">
    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-center">
        <h5>Usar campos de consulta da pesquisa avançada</h5>
    </div>
</div>
<div class="row justify-content-md-center div-linha">
    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-left">
        <h6>Você pode escolher uma das seguintes opções:</h6>
    </div>
</div>
<div class="row justify-content-md-center div-linha">
    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-left">
        <p class="mb-2 text-muted">
            &nbsp;&nbsp;&nbsp;<b>"Todas estas palavras (ex: casa+saude)"</b>: os resultados usarão todas as palavras
            inseridas.
        </p><br />
        <p class="mb-2 text-muted">
            &nbsp;&nbsp;&nbsp;<b>"Qualquer uma destas palavras (ex: casa saude)"</b>: os resultados incluirão pelo menos
            uma das palavras inseridas.
        </p>
    </div>
</div>

<div class="row justify-content-md-center div-linha">
    <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-left">
        <h6>A pesquisa tentará retornar os primeiros resultados o mais próximo das palavras informadas.</h6>
    </div>
</div>