<div class="container">
    <div class="row justify-content-md-center div-linha">
        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-center">
            <img src="../assets/img/logo-consulta-resultado.png" />
        </div>
    </div>
    <div class="row justify-content-md-center div-linha">
        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-center">
            <br />
            <h6 class="card-subtitle mb-2 text-muted">Ocorreu um problema temporário!</h6>            
        </div>
    </div>
    <div class="row justify-content-md-center div-linha">
        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-center">
            <img src="../../assets/img/erro.png" style="max-width: 150px;" class="cursor-hand" (click)="goHome()"/>   
        </div>
    </div>
    <div class="row justify-content-md-center div-linha">
        <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 text-center">
            <h6 class="card-subtitle mb-2 text-muted">Você será direcionado para a tela inicial</h6>
            <h4 class="card-subtitle mb-2 text-muted">{{segundos}}</h4>
            <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" [ngStyle]="{'width': percentualProgress() + 'px'}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
        </div>
    </div>
</div>