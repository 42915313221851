<div class="container">    
    <h4 class="card-subtitle mb-2 text-center">Selecione</h4>
    
    <div class="row justify-content-md-center div-linha">
        <div class="col-10 col-sm-3 col-md-3 col-xl-2 col-lg-2 text-center d-grid gap-2 mx-auto">
            <a class="btn btn-lg btn-primary" href="/consulta/ALL" role="button">Todos</a>
        </div>
    </div>

    <div class="row justify-content-md-center div-linha">
        <div class="col-10 col-sm-3 col-md-3 col-xl-2 col-lg-2 text-center d-grid gap-2 mx-auto">
            <a class="btn btn-lg btn-primary" href="/consulta/IPAM" role="button">Ipam</a>
        </div>
    </div>

    <div class="row justify-content-md-center div-linha">
        <div class="col-10 col-sm-3 col-md-3 col-xl-2 col-lg-2 text-center d-grid gap-2 mx-auto">
            <a class="btn btn-lg btn-primary" href="/consulta/PROJUR" role="button">Projur</a>
        </div>
    </div>

    <div class="row justify-content-md-center div-linha">
        <div class="col-10 col-sm-3 col-md-3 col-xl-2 col-lg-2 text-center d-grid gap-2 mx-auto">
            <a class="btn btn-lg btn-primary" href="/consulta/CONVENIO" role="button">Convênio</a>
        </div>
    </div>
</div>